.App {
  text-align: center;
  font-family: Pretendard;
}

body {
  margin: 0 !important;
}

a {
  text-decoration : none;
  color: black;
}

.find-id-message{
  font-size: 17px;
  font-family: Pretendard;
  margin-top: 15px;
}

@media (min-width: 0px){
  .css-nzinn5-MuiContainer-root {
      max-width: 475px !important;
  }
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Thin';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

.intro-title-img {
  position: relative;
}

.intro-title-img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.intro-header-title {
  position: absolute;
  top: 50%;
  right: 44%;
  font-size: 30px;
  font-weight: bold;
  color: white;
}
